import { useEffect, useState } from "react";
import { ReactComponent as Next } from "../static/icons/next.svg";
import { ReactComponent as Prev } from "../static/icons/previous.svg";

const Pagination = ({ page, count, handleChangePage }) => {

  const rowsPerPage = 10;

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  useEffect(() => {
    let startNumber = (page * rowsPerPage) + 1;
    if(count === 0){
      startNumber = 0;
    }
    setStart(startNumber);
    let endNumber = (page * rowsPerPage) + rowsPerPage;
    if(endNumber >= count){
      endNumber = count;
    }
    setEnd(endNumber);

  }, [page, count])

  return(
    <div className="pagination-container d-flex justify-content-end align-items-center">
      <button
        className="pagination-arrow d-flex align-items-center justify-content-center"
        disabled = {page === 0}
        onClick = {() => handleChangePage(page - 1)}
      >
        <Prev/>
      </button>

      <div className="pagination-count-container">
        {start} - {end} of {count}  
      </div>

      <button
        className="pagination-arrow d-flex align-items-center justify-content-center"
        onClick = {() => handleChangePage(page + 1)}
        disabled = {end === count}
      >
        <Next/>
      </button>
    </div>
  )
}

export default Pagination;