import RoutesConfig from './RoutesConfig';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/index.scss";

function App() {
  return (
    <RoutesConfig />
  );
}

export default App;
