import { Link } from "react-router-dom";

const Breadcrumbs = ({ links }) => {
  return(
    <ol className="breadcrumb paper">
      {
        links.map((link, ind) => {
          if(link.to){
            return(
              <li className="breadcrumb-item" key={ind}>
                <Link to={link.to}>
                  {link.title}
                </Link>
              </li>
            )
          }else{
            return(
              <li className="breadcrumb-item active" key={ind}>{link.title}</li>
            )
          }
        })
      }
    </ol>
  )
}

export default Breadcrumbs;