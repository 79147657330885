import { useEffect } from "react";

const PopupWrapper = ({ children, show }) => {
  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "auto";
  }, [show])
  return(
    <div className={`popup-container ${show ? "show" : ""}`}>
      {children}
    </div>
  )
}

export default PopupWrapper;