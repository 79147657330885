import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { messageTypes } from "../constants";
import ApplicationService from "../services/application";
import Statuses from "../data/UnderwritingStatus.json";
import DownloadService from "../services/download";
import { saveAs } from "file-saver";

const UnderwritingDetails = ({ data, showSnackbar, getData }) => {

  const [suggestedLoading, setSuggestedLoading] = useState("-");
  const [underwritingStatus, setUnderwritingStatus] = useState("Accepted as standard");
  const [isSaving, setIsSaving] = useState(false);
  const [reasonForDecision, setReasonForDecision] = useState("");
  const [reinsurerReferenceNo, setReinsurerReferenceNo] = useState("");
  const [isDownloadingLetter, setIsDownloadingLetter] = useState(false);
  const [medicalRequired, setMedicalRequired] = useState("No");
  const [errors, setErrors] = useState([])
  
  const params = useParams();

  useEffect(() => {

    if(data.underwriting_details_sent){
      setSuggestedLoading(data.underwriting_decision.underwriting_loading);
      setUnderwritingStatus(data.underwriting_decision.underwriting_status);
      setReasonForDecision(data.underwriting_decision.reason_for_decision);
      setReinsurerReferenceNo(data.underwriting_decision.reinsurer_reference_no);
    }else{
      const {bmi} = data;
  
      if(bmi <= 17 || bmi > 36){
        setUnderwritingStatus("Medical Required")
      }
      if(bmi > 17 && bmi <= 32){
        setSuggestedLoading(0)
      }
  
      if(bmi > 32 && bmi <= 34){
        setSuggestedLoading(25)
      }
  
      if(bmi > 34 && bmi <= 36){
        setSuggestedLoading(50)
      }
    }

  }, [data])

  const handleSave = () => {
    let errorsArr = [];
    const extra_loading = parseInt(suggestedLoading);

    if(isNaN(extra_loading) || extra_loading < 0){
      errorsArr.push("extra_loading")
    }
    if(extra_loading > 0 && underwritingStatus === "Accepted as standard"){
      errorsArr.push("underwriting_status")
    }
    setErrors(errorsArr);

    if(errorsArr.length === 0){
      setIsSaving(true);
      const id = params.id;
      const data = {
        underwriting_premium : getSuggestedPremium(),
        underwriting_loading : suggestedLoading,
        underwriting_status : underwritingStatus,
        reason_for_decision : reasonForDecision,
        reinsurer_reference_no : reinsurerReferenceNo
      }
      ApplicationService.savePremiumDetails(id, data).then((res) => {
        showSnackbar("Underwriting decision sent successfully !", messageTypes.SUCCESS);
        getData();
      }).finally(() => {
        setIsSaving(false);
      })
    }
  }

  const getSuggestedPremium = () => {
    let base_premium = 240;
    const loading_percentage = parseFloat(suggestedLoading || 0);
    const additional_premium = (base_premium * loading_percentage) / 100;

    return parseInt(base_premium + additional_premium);
  }

  const downloadLetter = () => {
    setIsDownloadingLetter(true);
    let fileName = data.id;
    const decision_letters = data.decision_letters
    if(decision_letters && decision_letters.length){
      console.log(decision_letters);
      fileName += `-${decision_letters[0].created_at}`;
    }

    DownloadService.downloadDocument("decision-letter", fileName).then((res) => {
      saveAs(res.data, `${data.id}-Decision-Letter.pdf`);
      setIsDownloadingLetter(false);
    })
  }

  const showError = errors.length !== 0;

  return(
    <div className="paper">
      <div className="paper-title">
        Underwriting Details
      </div>
      
      <div className="detail-container d-flex align-items-center">
        <div className="title">
          Medical Requirement
        </div>
        <div className="value d-flex align-items-center justify-content-between flex-grow-1">
          <select
            className="w-100"
            value={medicalRequired}
            onChange={(e) => setMedicalRequired(e.target.value)}
          >
            <option value='Yes'> Yes </option>
            <option value='No'> No </option>
          </select>
        </div>
      </div>

      <div className="detail-container d-flex mt-3 align-items-center">
        <div className="title">
          Extra Loading %
        </div>
        <div className="value d-flex align-items-center justify-content-between flex-grow-1">
          <input
            type="number"
            value={suggestedLoading}
            className={`w-100 ${showError && errors.includes("extra_loading") ? "has-error" : ""}`}
            onChange={(e) => setSuggestedLoading(e.target.value)}
          /> 
        </div>
      </div>

      <div className="detail-container d-flex mt-3 align-items-center">
        <div className="title">
          Reinsurer reference no.
        </div>
        <div className="value d-flex align-items-center justify-content-between flex-grow-1">
          <input
            type="text"
            value={reinsurerReferenceNo}
            className="w-100"
            onChange={(e) => setReinsurerReferenceNo(e.target.value)}
          /> 
        </div>
      </div>

      <div className="detail-container d-flex mt-3">
        <div className="title">
          Premium Rate
        </div>
        <div className="value">
          {getSuggestedPremium()} Baizas per 1000 outstanding amount per month
        </div>
      </div>

      <div className="detail-container d-flex mt-3 align-items-center">
        <div className="title">
          Underwriting Decision
        </div>
        <div className="value d-flex align-items-center justify-content-between flex-grow-1">
          <select
            className={`w-100 ${showError && errors.includes("underwriting_status") ? "has-error" : ""}`}
            value={underwritingStatus}
            onChange={(e) => setUnderwritingStatus(e.target.value)}
          >
            {
              Statuses.map((status) => 
                <option value={status} key = {status}> {status} </option>
              )
            }
          </select>
        </div>
      </div>

      <div className="detail-container mt-3">
        <div className="title">
          Reason for Decision
        </div>
        <div className="value">
          <textarea
            className="w-100 mt-2"
            rows={5}
            onChange={(e) => setReasonForDecision(e.target.value)}
            value={reasonForDecision}
          ></textarea>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-3">
        <div>
          {
            data.underwriting_details_sent &&
            <button
              onClick={downloadLetter}
              disabled = {isDownloadingLetter}
            >
              {isDownloadingLetter ? "Downloading . . ." : "Download Decision Letter"}
            </button>
          }
        </div>
        <button
          onClick={handleSave}
          disabled = {isSaving}
        >
          {isSaving ? "Sending . . ." : "Send Underwriting Decision"}
        </button>
      </div>
    </div>
  )
}

export default UnderwritingDetails;