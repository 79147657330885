export const allowOnlyNumbers = (e, maxlength = undefined) => {
  const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  
  const key = e.key;
  const value = e.target.value;

  if(['Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)){
    return;
  }
  if(
    !allowedKeys.includes(key) ||
    ( maxlength && value.length > maxlength - 1)
  ){
    e.preventDefault()
  }
}