import axios from "axios";
import config from "./config";

const baseUrl = config.baseUrl;

const download = {};

download.downloadDocument = async (document_type, file_name) => {
  const url = `${baseUrl}/download-document/${document_type}/${file_name}`;
  const res = await axios({
    url,
    responseType : "blob"
  });
  return res;
}

export default download;