export const messageTypes = {
  ERROR : "ERROR",
  SUCCESS : "SUCCESS",
  WARNING : "WARNING",
  INFO : "INFO"
}

export const medicalTests = {
  "MER" : "MER",
  "URINE_ANALYSIS" : "Urine Analysis",
  "LAB_1" : "LAB 1",
  "LAB_2" : "LAB 2",
  "ECG" : "ECG",
  "TMT" : "TMT",
  "HIV" : "HIV",
  "HCV" : "HCV"
}

export const LOAN_TYPES = {
  NEW : "NEW",
  TOP_UP : "TOP-UP",
  RESCHEDULE : "RESCHEDULE"
}