import { useEffect, useState } from "react";
import { allowOnlyNumbers } from "../../helpers";
import { ReactComponent as DeleteIcon } from "../../static/icons/delete-icon.svg";
import { LOAN_TYPES, messageTypes } from "../../constants";
import ApplicationService from "../../services/application";

const LoanDetails = ({
  details,
  value_field,
  handleDetailChange,
  application_id,
  showSnackbar
}) => {
  
  let total_existing_loan_amount = 0;
  let total_loan_amount_with_topup = 0;
  let total_additional_loan_amount = 0;

  const [isSaving, setIsSaving] = useState(false);
  
  const isDataFilled = () => {
    if(
      details.loan_type === "" ||
      (
        details.loan_request_type === LOAN_TYPES.NEW &&
        details.new_loan_amount === ""
      ) ||
      (
        details.loan_request_type === LOAN_TYPES.RESCHEDULE &&
        details.reason_for_reschedule === ""
      ) ||
      details.loan_tenure === "" ||
      details.loan_request_type === "" ||
      !isLoansDataFilled()
    ){
      return false;
    }
    return true;
  }

  useEffect(() => {
    if(details.loan_request_type !== LOAN_TYPES.NEW && details.have_existing_loan !== 'Yes'){
      handleDetailChange('have_existing_loan', 'Yes', value_field)
    }
  }, [details, handleDetailChange, value_field])

  const getTotalExposureAmount = () => {
    let total_loan_exposure_amount = 0;
    let existing_loan_amount = 0;
    const loan_amount = parseInt(details.new_loan_amount || 0);
    
    if(
      details.loan_request_type === LOAN_TYPES.NEW &&
      details.have_existing_loan === "Yes"
    ){
      previous_loans.forEach((loan, ind) => {
        let currentAmount = parseInt(loan.existing_amount || 0);
        existing_loan_amount += currentAmount
      })
    }
    
    total_loan_exposure_amount = loan_amount + existing_loan_amount;

    if([LOAN_TYPES.TOP_UP, LOAN_TYPES.RESCHEDULE].includes(details.loan_request_type)){
      let topup_loan_amount = 0;
      previous_loans.forEach((loan, ind) => {
        let currentAmount = parseInt(loan.existing_amount || 0);
        let newLoanAmount = details.loan_request_type === LOAN_TYPES.RESCHEDULE ? 0 : currentAmount;

        if(loan.want_topup){
          let topup_amount = parseInt(loan.topup_amount || 0);
          newLoanAmount += topup_amount;
        }

        topup_loan_amount += newLoanAmount;
      })

      total_loan_exposure_amount = topup_loan_amount
    }
    
    return total_loan_exposure_amount;
  }

  const blank_loan_obj = {
    type : "",
    existing_amount : "",
    existing_tenure : "",
    want_topup : false,
    topup_amount : "",
    new_tenure : ""
  }
  const [previous_loans, setPreviousLoans] = useState(details.previous_loans || [blank_loan_obj]);

  const deleteRow = (ind) => {
    const previousLoans = [...previous_loans];
    previousLoans.splice(ind, 1);
    setPreviousLoans(previousLoans);
    handleDetailChange('previous_loans', previousLoans, value_field)
  }

  const addLoanRow = () => {
    const previousLoans = [...previous_loans, blank_loan_obj];
    setPreviousLoans(previousLoans);
    handleDetailChange('previous_loans', previousLoans, value_field)
  }

  const isLoansDataFilled = () => {

    let blank_count = 0;
    if(
      [LOAN_TYPES.TOP_UP, LOAN_TYPES.RESCHEDULE].includes(details.loan_request_type) ||
      details.have_existing_loan === "Yes"
    ){
      previous_loans.forEach((loan) => {
        if(
          loan.type === "" ||
          loan.existing_amount === "" ||
          loan.existing_tenure === "" ||
          (
            (
              [LOAN_TYPES.TOP_UP, LOAN_TYPES.RESCHEDULE].includes(details.loan_request_type) &&
              loan.want_topup
            ) &&
            (
              loan.topup_amount === "" ||
              loan.new_tenure === ""
            )
          ) ||
          (
            loan.type === "HL" && ((loan.want_topup && parseInt(loan.new_tenure) > 300) || parseInt(loan.existing_tenure) > 300)
          ) ||
          (
            loan.type === "PL" && ((loan.want_topup && parseInt(loan.new_tenure) > 150) || parseInt(loan.existing_tenure) > 150)
          )
        ){
          blank_count++;
        }
      })
    }

    if((
      [LOAN_TYPES.TOP_UP, LOAN_TYPES.RESCHEDULE].includes(details.loan_request_type) ||
      (details.loan_request_type === LOAN_TYPES.NEW && details.have_existing_loan === "Yes")
    ) && previous_loans.length === 0){
      blank_count++;
    }

    return blank_count === 0;
  }

  const handleLoanDetailChange = (name, value, ind) => {
    const previousLoans = [...previous_loans];
    const selectedLoanObj = {...previousLoans[ind]}
    selectedLoanObj[name] = value;
    previousLoans[ind] = selectedLoanObj;
    setPreviousLoans(previousLoans);
    handleDetailChange('previous_loans', previousLoans, value_field)
  }

  const [errors, setErrors] = useState([])

  const handleClickSave = () => {
    let errorsArr = [];
    if(
      (details.loan_type === "HL" && parseInt(details.loan_tenure) > 300) ||
      (details.loan_type === "PL" && parseInt(details.loan_tenure) > 150)
    ){
      errorsArr.push("loan_tenure")
    }
    setErrors(errorsArr);
    if(errorsArr.length === 0){
      setIsSaving(true);

      let total_loan_exposure_amount = 0;
      let existing_loan_amount = 0;

      const loan_details = details;
      const previous_loans = loan_details.previous_loans;
      let new_loan_amount = 0
      
      if(
        loan_details.loan_request_type === LOAN_TYPES.NEW &&
        loan_details.have_existing_loan === "Yes"
      ){
        previous_loans.forEach((loan, ind) => {
          let currentAmount = parseInt(loan.existing_amount || 0);
          existing_loan_amount += currentAmount;
        })
        const loan_amount = parseInt(loan_details.new_loan_amount || 0);
        new_loan_amount = loan_amount;
        total_loan_exposure_amount = loan_amount + existing_loan_amount;
      }
      

      if([LOAN_TYPES.TOP_UP, LOAN_TYPES.RESCHEDULE].includes(loan_details.loan_request_type)){
        let topup_loan_amount = 0;
        previous_loans.forEach((loan, ind) => {
          let currentAmount = parseInt(loan.existing_amount || 0);
          existing_loan_amount += currentAmount;

          let newLoanAmount = loan_details.loan_request_type === LOAN_TYPES.RESCHEDULE ? 0 : currentAmount;

          if(loan.want_topup){
            let topup_amount = parseInt(loan.topup_amount || 0);
            newLoanAmount += topup_amount;
            new_loan_amount += topup_amount;
          }

          topup_loan_amount += newLoanAmount;
        })

        total_loan_exposure_amount = topup_loan_amount
      }

      details.total_loan_exposure_amount = total_loan_exposure_amount;
      details.new_loan_amount = new_loan_amount;
      details.existing_loan_amount = existing_loan_amount;

      const update_data = {
        [value_field] : {
          ...details
        }
      }
      ApplicationService.updateApplication(application_id, update_data, "").then((res) => {
        setIsSaving(false);
        showSnackbar("Loan details updated successfully !", messageTypes.SUCCESS)
      }).catch(() => {
        setIsSaving(false);
        showSnackbar("Loan details not updated !", messageTypes.ERROR)
      })
    }
  }

  const showError = errors.length !== 0;

  return(
    <div className="paper">
      <div className="paper-title">
        Loan Details
      </div>

      <div className="grid-row">

      <div className="input-container">
          <label> Loan request type </label>
          <div className="tick-container d-flex align-items-center">
            <label className="custom-check-label d-flex align-items-center cursor-pointer">
              <input
                type="radio"
                className="d-none"
                name="loan_request_type"
                value={LOAN_TYPES.NEW}
                checked = {details.loan_request_type === LOAN_TYPES.NEW}
                onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
              />
              <span></span>
              New
            </label>
            <label className="custom-check-label d-flex align-items-center cursor-pointer">
              <input
                type="radio"
                className="d-none"
                name="loan_request_type"
                value={LOAN_TYPES.TOP_UP}
                checked = {details.loan_request_type === LOAN_TYPES.TOP_UP}
                onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
              />
              <span></span>
              Top-up
            </label>
            <label className="custom-check-label d-flex align-items-center cursor-pointer">
              <input
                type="radio"
                className="d-none"
                name="loan_request_type"
                value={LOAN_TYPES.RESCHEDULE}
                checked = {details.loan_request_type === LOAN_TYPES.RESCHEDULE}
                onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
              />
              <span></span>
              Reschedule
            </label>
          </div>
        </div>

        <div className="input-container">
          <label> Loan Type </label>
          <select
            className="w-100"
            name="loan_type"
            value={details.loan_type}
            onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
          >
            <option value="" disabled> Select Loan Type </option>
            <option value="HL"> HL </option>
            <option value="PL"> PL </option>
          </select>
        </div>

        {
          details.loan_request_type === LOAN_TYPES.NEW &&
          <div className="input-container">
            <label> New Loan Amount </label>
            <input
              type="text"
              className="w-100"
              name = "new_loan_amount"
              value={details.new_loan_amount}
              onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
              onKeyDown={(e) => allowOnlyNumbers(e)}
            />
          </div>
        }

        <div className="input-container">
          <label> Loan Tenure - Months </label>
          <input
            type="text"
            className={`w-100 ${showError && errors.includes("loan_tenure") ? "has-error" : ""}`}
            name = "loan_tenure"
            value={details.loan_tenure}
            onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
            onKeyDown={(e) => allowOnlyNumbers(e)}
          />
          {
            (showError && errors.includes("loan_tenure")) &&
            <div className="error-message"> Loan Tenure should be less than {details.loan_type === "HL" ? "300" : "150"} months </div>
          }
        </div>

        {
          details.loan_request_type === LOAN_TYPES.RESCHEDULE &&
          <div className="input-container">
            <label> Reason for Reschedule </label>
            <textarea
              value={details.reason_for_reschedule}
              className = "w-100 question-detail mt-0"
              placeholder="Provide more details"
              rows={3}
              name={"reason_for_reschedule"}
              onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
            ></textarea>
          </div>
        }

        {
          details.loan_request_type === LOAN_TYPES.NEW &&
          <div className="input-container">
            <label> Do you have any existing loan with Bank Dhofar ?</label>
            <div className="tick-container d-flex align-items-center">
              <label className="custom-check-label d-flex align-items-center cursor-pointer">
                <input
                  type="radio"
                  className="d-none"
                  name="have_existing_loan"
                  value="Yes"
                  checked = {details.have_existing_loan === "Yes"}
                  onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
                />
                <span></span>
                Yes
              </label>
              <label className="custom-check-label d-flex align-items-center cursor-pointer">
                <input
                  type="radio"
                  className="d-none"
                  name="have_existing_loan"
                  value="No"
                  checked = {details.have_existing_loan === "No"}
                  onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
                />
                <span></span>
                No
              </label>
            </div>
          </div>
        }

        <div className="input-container">
          <label> Total loan exposure amount (after adjusting settlements) </label>
          <input
            type="text"
            className="w-100"
            name = "total_loan_exposure_amount"
            value={getTotalExposureAmount()}
            disabled
            onChange={(e) => e.preventDefault()}
          />
        </div>

        {
          (
            [LOAN_TYPES.TOP_UP, LOAN_TYPES.RESCHEDULE].includes(details.loan_request_type) ||
            (details.loan_request_type === LOAN_TYPES.NEW && details.have_existing_loan === "Yes")
          ) &&
          <div className="full-width">
            <table className="w-100 table table-bordered align-middle">
              <thead>
                <tr>
                  <th colSpan={4} className="text-center topup-loan-header">
                    Existing Loan Details
                  </th>
                  {
                    details.loan_request_type !== LOAN_TYPES.NEW &&
                    <th colSpan={details.loan_request_type === LOAN_TYPES.RESCHEDULE ? 3 : 4} className="text-center topup-loan-header">
                      {details.loan_request_type === LOAN_TYPES.RESCHEDULE ? "Rescheduling of Loan" : "Top-Up"}
                    </th>
                  }
                  {
                    previous_loans.length > 1 &&
                    <th className="text-center topup-loan-header">
                      Actions
                    </th>
                  }
                </tr>
                <tr>
                  <th>
                    Existing Loan
                  </th>
                  <th>
                    Type
                  </th>
                  <th>
                    Amount
                  </th>
                  <th>
                    Remaining Tenure
                  </th>

                  {
                    details.loan_request_type !== LOAN_TYPES.NEW &&
                    <>
                      <th className="text-center">
                        Select
                      </th>
                      <th>
                        {details.loan_request_type === LOAN_TYPES.RESCHEDULE ? "Reschedule Amount" : "Additional Topup Amount"}
                      </th>
                      {
                        details.loan_request_type !== LOAN_TYPES.RESCHEDULE &&
                        <th>
                          {details.loan_request_type === LOAN_TYPES.RESCHEDULE ? "Amount after rescheduling" : "Amount after Top-Up"}
                        </th>
                      }
                      <th>
                        New Tenure
                      </th>
                    </>
                  }

                  {
                    previous_loans.length > 1 &&
                    <th className="text-center">
                      Delete
                    </th>
                  }
                </tr>
              </thead>

              <tbody>
                {
                  previous_loans.map((loan, ind) => {
                    let currentAmount = parseInt(loan.existing_amount || 0);
                    let newLoanAmount = currentAmount;

                    total_existing_loan_amount += currentAmount;

                    if(loan.want_topup){
                      let topup_amount = parseInt(loan.topup_amount || 0);
                      total_additional_loan_amount += topup_amount;
                      newLoanAmount += topup_amount;
                    }

                    total_loan_amount_with_topup += newLoanAmount;

                    let hasTenureError = (loan.type === "HL" && parseInt(loan.new_tenure) > 300) ||
                    (loan.type === "PL" && parseInt(loan.new_tenure) > 150)

                    let hasExistingTenureError = (loan.type === "HL" && parseInt(loan.existing_tenure) > 300) ||
                    (loan.type === "PL" && parseInt(loan.existing_tenure) > 150)

                    return(
                      <tr key={ind}>
                        <td>
                          <div className="d-flex align-items-center">
                            Loan {ind + 1}
                          </div>
                        </td>
                        <td width={140}>
                          <div className="input-container">
                            <select
                              className="w-100"
                              name="type"
                              value={loan.type}
                              onChange={(e) => handleLoanDetailChange(e.target.name, e.target.value, ind)}
                            >
                              <option value="" disabled> Loan Type </option>
                              <option value="HL"> HL </option>
                              <option value="PL"> PL </option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="input-container">
                            <input
                              type="text"
                              className="w-100"
                              name = "existing_amount"
                              value={loan.existing_amount}
                              onChange={(e) => handleLoanDetailChange(e.target.name, e.target.value, ind)}
                              onKeyDown={(e) => allowOnlyNumbers(e)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="input-container">
                            <input
                              type="text"
                              className={`w-100 ${hasExistingTenureError ? "has-error" : ""}`}
                              name = "existing_tenure"
                              value={loan.existing_tenure}
                              onChange={(e) => handleLoanDetailChange(e.target.name, e.target.value, ind)}
                              onKeyDown={(e) => allowOnlyNumbers(e)}
                            />
                          </div>
                        </td>

                        {
                          details.loan_request_type !== LOAN_TYPES.NEW && 
                          <>
                            <td>
                              <div className="input-container d-flex justify-content-center">

                                <label className="custom-check-label d-inline-flex align-items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    className="d-none"
                                    name="want_topup"
                                    checked = {loan.want_topup}
                                    onChange={(e) => handleLoanDetailChange(e.target.name, e.target.checked, ind)}
                                  />
                                  <span className="me-0"></span>
                                </label>
                              </div>
                            </td>
                            <td style={{ width : "250px" }}>
                              <div className="input-container">
                                {
                                  loan.want_topup ?
                                  <input
                                    type="number"
                                    className="w-100"
                                    name = "topup_amount"
                                    value={loan.topup_amount}
                                    onChange={(e) => handleLoanDetailChange(e.target.name, e.target.value, ind)}
                                  /> : 
                                  <> 0 </>
                                }
                              </div>
                            </td>
                            {
                              details.loan_request_type !== LOAN_TYPES.RESCHEDULE &&
                              <td>
                                {newLoanAmount}
                              </td>
                            }
                            <td style={{ width : "200px" }}>
                              <div className="input-container">
                                {
                                  loan.want_topup ?
                                  <input
                                    type="text"
                                    className={`w-100 ${hasTenureError ? "has-error" : ""}`}
                                    name = "new_tenure"
                                    value={loan.new_tenure}
                                    onChange={(e) => handleLoanDetailChange(e.target.name, e.target.value, ind)}
                                    onKeyDown={(e) => allowOnlyNumbers(e)}
                                  /> :
                                  <> {loan.existing_tenure} </>
                                }
                              </div>
                            </td>
                          </>
                        }
                        {
                          previous_loans.length > 1 &&
                          <td className="text-center">
                            <DeleteIcon
                              className="cursor-pointer"
                              onClick={() => deleteRow(ind)}
                            />
                          </td>
                        }
                      </tr>
                    )
                  })
                }

                <tr>
                  <th colSpan={2}>
                    Total
                  </th>
                  <th className="text-center">
                    {total_existing_loan_amount}
                  </th>
                  {
                    details.loan_request_type !== LOAN_TYPES.NEW &&
                    <>
                      <th></th>
                      <th></th>
                      <th className="text-center">
                        {total_additional_loan_amount}
                      </th>
                      {
                        details.loan_request_type === LOAN_TYPES.TOP_UP ?
                        <th className="text-center">
                          {total_loan_amount_with_topup}
                        </th> : 
                        <th></th>
                      }
                    </>
                  }
                  {
                    details.loan_request_type !== LOAN_TYPES.RESCHEDULE &&
                    <th></th>
                  }
                  {
                    (details.loan_request_type !== LOAN_TYPES.RESCHEDULE && previous_loans.length > 1) &&
                    <th></th>
                  }
                </tr>

                <tr>
                  <td
                    colSpan={previous_loans.length > 1 ? 9 : 8}
                  >
                    <div className="d-flex justify-content-end">
                      <button
                        onClick={addLoanRow}
                      >
                        Add Loan
                      </button>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }

        <div className="d-flex full-width justify-content-end">

          <button
            onClick = {handleClickSave}
            disabled = {!isDataFilled() || isSaving}
          >
            {isSaving ? "Saving . . ." : "Save"}
          </button>
        </div>

      </div>

    </div>
  )
}

export default LoanDetails;