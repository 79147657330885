import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Home from "./pages/Home";
import Login from "./pages/Login";
import withSnackbar from "./components/Snackbar";
import Agents from "./pages/Agents";
import ApplicationView from "./pages/ApplicationView";
import Header from "./components/Header";
import Report from "./pages/Report";
import ApplicationEdit from "./pages/ApplicationEdit";
import ApplicationViewIndividual from "./pages/ApplicationViewIndividual";


const RoutesConfig = ({ showSnackbar }) => {
  return(
    <Router>
      <Header showSnackbar = {showSnackbar} />
      <Routes>
        <Route index element = {<Home showSnackbar = {showSnackbar} />} />
        <Route path="/login" element = {<Login showSnackbar = {showSnackbar} />} />
        <Route path="/agents" element = {<Agents showSnackbar = {showSnackbar} />} />
        <Route path="/report" element = {<Report showSnackbar = {showSnackbar} />} />
        <Route path="/application/group/:id" element = {<ApplicationView showSnackbar = {showSnackbar} />} />
        <Route path="/application/individual/:id" element = {<ApplicationViewIndividual showSnackbar = {showSnackbar} />} />
        <Route path="/application/edit/:id" element = {<ApplicationEdit showSnackbar = {showSnackbar} />} />
      </Routes>
    </Router>
  )
}

export default withSnackbar(RoutesConfig);