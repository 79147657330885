import { useEffect, useState } from "react";
import { medicalTests, messageTypes } from "../constants";
import PopupWrapper from "./PopupWrapper";
import { ReactComponent as Close } from "../static/icons/cross-icon.svg";
import moment from "moment";
import MedicalCenters from "../data/MedicalCenters.json";
import ApplicationService from "../services/application";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import CustomDateInput from "./CustomDateInput";

const MedicalPopup = ({ data, showSnackbar, getData }) => {
  
  const [requiredMedicalTests, setRequiredMedicalTests] = useState([]);
  const [showMedicalPopup, setShowMedicalPopup] = useState(false);
  const [medicalCenter, setMedicalCenter] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [changeMedicalCenter, setChangeMedicalCenter] = useState(false);
  const [changeMedicalAppointment, setChangeMedicalAppointment] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState({});
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [isSending, setIsSending] = useState(false);
  
  const params = useParams();

  useEffect(() => {

    if(!data.sent_to_medical_center){
      setMedicalCenter(data.medical_details.desired_medical_center);
      setAppointmentTime(data.medical_details.desired_medical_appointment_time);
      
      const { customer_details, bmi, loan_details } = data;

      const loan_amount = parseFloat(loan_details.total_loan_exposure_amount);
      const age = customer_details.age;
  
      let medicalReuirements = [];
  
      if(age >= 50 && loan_amount <= 40000){
        medicalReuirements = [medicalTests.MER, medicalTests.LAB_1]
      }
      if(age >= 50 && loan_amount > 40000 && loan_amount <= 80000){
        medicalReuirements = [medicalTests.MER, medicalTests.LAB_1, medicalTests.ECG]
      }
      if(loan_amount > 80000 && loan_amount <= 200000){
        medicalReuirements = [medicalTests.MER, medicalTests.LAB_2, medicalTests.ECG]
      }
      if(loan_amount > 200000 && loan_amount <= 400000){
        medicalReuirements = [medicalTests.MER, medicalTests.LAB_2, medicalTests.TMT, medicalTests.HIV]
      }
  
      if(bmi >= 37 && bmi <= 45){
        if(!medicalReuirements.includes(medicalTests.URINE_ANALYSIS)){
          medicalReuirements.push(medicalTests.URINE_ANALYSIS)
        }
        if(!medicalReuirements.includes(medicalTests.MER)){
          medicalReuirements.push(medicalTests.MER)
        }
        if(!medicalReuirements.includes(medicalTests.LAB_2)){
          medicalReuirements.push(medicalTests.LAB_2)
        }
  
        if(age >= 50){
          if(!medicalReuirements.includes(medicalTests.LAB_2)){
            medicalReuirements.push(medicalTests.LAB_2)
          }
        }
      }

      const underwriting_requirements = data.underwriting_requirements
      if(underwriting_requirements){
        medicalReuirements = [];

        if(underwriting_requirements.includes("MER")){
          medicalReuirements.push(medicalTests.MER)
        }
        if(underwriting_requirements.includes("Lab 1")){
          medicalReuirements.push(medicalTests.LAB_1)
        }
        if(underwriting_requirements.includes("Lab 2")){
          medicalReuirements.push(medicalTests.LAB_2)
        }
        if(underwriting_requirements.includes("ECG")){
          medicalReuirements.push(medicalTests.ECG)
        }
        if(underwriting_requirements.includes("TMT")){
          medicalReuirements.push(medicalTests.TMT)
        }
        if(underwriting_requirements.includes("HIV")){
          medicalReuirements.push(medicalTests.HIV)
        }
        if(underwriting_requirements.includes("HCV")){
          medicalReuirements.push(medicalTests.HCV)
        }
      }
      
      setRequiredMedicalTests(medicalReuirements)
    }
    else{
      setMedicalCenter(data.confirmed_medical_center_details.medical_center);
      setAppointmentTime(data.confirmed_medical_center_details.medical_appointment_time);
    }
    
  }, [data])

  useEffect(() => {
    const selected_center = [...MedicalCenters].find((center) => center.code === medicalCenter);
    setSelectedCenter(selected_center || {});
  }, [medicalCenter])

  const Checkbox = ({ value, title }) => {
    return (
      <label className="custom-checkbox-label cursor-pointer d-flex align-items-center">
        <input
          type="checkbox"
          className="d-none"
          checked = {requiredMedicalTests.includes(value)}
          onChange = {() => handleChangeMedicalRequirement(value)}
        />

        <span className="d-flex align-items-center justify-content-center flex-shrink-0"></span>

        {title}
      </label>
    )
  }

  const handleChangeMedicalRequirement = (value) => {
    let _requiredMedicalTests = [...requiredMedicalTests];
    const ind = _requiredMedicalTests.indexOf(value);
    
    if(ind < 0){
      _requiredMedicalTests.push(value)
    }
    else{
      _requiredMedicalTests.splice(ind, 1)
    }

    setRequiredMedicalTests(_requiredMedicalTests)
  }

  const sendDetailsToMedicalCenter = () => {
    setIsSending(true);
    const data = {
      medical_center : medicalCenter,
      medical_appointment_time : appointmentTime,
      medical_tests : requiredMedicalTests,
      medical_test_additional_information : additionalInformation
    }
    const id = params.id;
    ApplicationService.sendMTRF(id, data).then((res) => {
      console.log(res);
      showSnackbar("MTRF sent successfully", messageTypes.SUCCESS);
      setShowMedicalPopup(false);
      getData();
    }).finally(() => {
      setIsSending(false);
    })
  }

  return(
    <>
      <button onClick={() => setShowMedicalPopup(true)}>
        Send MTRF
      </button>

      <PopupWrapper show={showMedicalPopup}>
        <div className="popup medical-test-request-popup">
          <div className="popup-title d-flex align-items-center justify-content-between">
            Medical Test Request Form
            <Close height={28} className = "cursor-pointer" onClick={() => setShowMedicalPopup(false)}/>
          </div>

          <div className="d-grid">
            <div>
              <div className="title mb-0">
                Medical Center
              </div>
              <div className="d-flex justify-content-between align-items-center detail">
                {
                  changeMedicalCenter ?
                  <select
                    className="flex-grow-1"
                    value={medicalCenter}
                    onChange={(e) => setMedicalCenter(e.target.value)}
                  >
                    {
                      MedicalCenters.map((medical_center) => 
                        <option
                          key={medical_center.code}
                          value={medical_center.code}
                        >
                          {medical_center.name}, {medical_center.location}
                        </option>
                      )
                    }
                  </select> :
                  <>
                    {selectedCenter.name}, {selectedCenter.location}
                  </>
                }
                {
                  changeMedicalCenter ?
                  <button
                    className="button-sm flex-shrink-0"
                    onClick={() => setChangeMedicalCenter(false)}
                  >
                    Save
                  </button> :
                  <button
                    className="button-sm button-bordered flex-shrink-0"
                    onClick={() => setChangeMedicalCenter(true)}
                  >
                    Change
                  </button>  
                }
              </div>

              <div className="title mb-0">
                Appointment Date
              </div>
              <div className="d-flex justify-content-between align-items-center detail">
                <div className="flex-grow-1" style={{ marginRight : "20px" }}>
                  {
                    changeMedicalAppointment ? 
                    <>
                      <DatePicker
                        selected={new Date(appointmentTime)}
                        onChange={(date) => setAppointmentTime(date)}
                        showMonthDropdown
                        showYearDropdown
                        dateFormat="dd-MM-yyyy hh:mm aa"
                        showTimeSelect
                        timeFormat="HH:mm"
                        customInput={<CustomDateInput />}
                        dropdownMode="select"
                        placeholderText="DD-MM-YYYY HH:MM"
                      />
                    </>
                    :
                    <>
                      {moment(appointmentTime).format("DD-MM-YYYY hh:mm A")}
                    </>
                  }
                </div>
                {
                  changeMedicalAppointment ?
                  <button
                    className="button-sm"
                    onClick={() => setChangeMedicalAppointment(false)}
                  >
                    Save
                  </button> :
                  <button
                    className="button-sm button-bordered"
                    onClick={() => setChangeMedicalAppointment(true)}
                  >
                    Change
                  </button>

                }
              </div>
              
              <div className="title">
                Additional Information
              </div>
              <textarea
                className="w-100 medical-information-box"
                value={additionalInformation}
                onChange={(e) => setAdditionalInformation(e.target.value)}
              ></textarea>
            </div>
            <div>
              <div className="title">
                Medical Tests to be done
              </div>

              <Checkbox
                value={medicalTests.MER}
                title = "Medical Examination Report"
              />
              <Checkbox
                value={medicalTests.URINE_ANALYSIS}
                title = "Urine Analysis"
              />
              <Checkbox
                value={medicalTests.LAB_1}
                title = "Lab 1"
              />
              <Checkbox
                value={medicalTests.LAB_2}
                title = "Lab 2"
              />
              <Checkbox
                value={medicalTests.ECG}
                title = "ECG"
              />
              <Checkbox
                value={medicalTests.TMT}
                title = "TMT"
              />
              <Checkbox
                value={medicalTests.HIV}
                title = "HIV"
              />

            </div>
          </div>

          <div className="d-flex justify-content-end" style={{ marginTop : "20px" }}>
            <button
              onClick={sendDetailsToMedicalCenter}
              disabled = {isSending}
            >
              {
                isSending ? "Sending . . ." : "Send MTRF to Medical Center"
              }
            </button>
          </div>
        </div>
      </PopupWrapper>
    </>
  )
}

export default MedicalPopup;