import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import _ from "lodash";
import moment from 'moment';
import { LOAN_TYPES } from '../constants';
import Tick from "../static/icons/tick-icon-black.png";
import OpenSansMedium from "../static/fonts/Open-Sans/OpenSans-Medium.ttf"
import OpenSansBold from "../static/fonts/Open-Sans/OpenSans-Bold.ttf"

Font.register({ family : "OpenSans", fonts : [
  { src : OpenSansMedium },
  { src : OpenSansBold, fontWeight : 700 }
]});

const styles = StyleSheet.create({
  page: {
    padding : 10,
    fontSize : 10,
    fontFamily : "OpenSans"
  },
  container : {
    border : "2pt solid #666666",
    borderRadius : 4
  },
  row : {
    display : "flex",
    flexDirection : "row"
  },
  title : {
    padding : 10,
    fontSize : 14,
    textAlign : "center",
    borderBottom : "1pt solid #666666",
    marginBottom : 10
  },
  half_column : {
    width : "50%"
  },
  value_row : {
    marginBottom : 4,
    alignItems : "center"
  },
  label : {
    width : 120,
    color : "rgba(0,0,0,0.7)",
    paddingLeft : 10,
    fontSize : 8.5
  },
  value : {
    flexGrow : 1,
    textTransform : "uppercase",
    fontSize : 8.5
  },
  status_row : {
    marginTop : 10,
    paddingTop : 4,
    borderTop : "1pt solid #666666",
    borderBottom : "1pt solid #666666"
  },
  detail_row : {
    borderTop : "1pt solid #333333",
    borderBottom : "1pt solid #333333",
    marginTop : -1
  },
  loan_detail_column : {
    width : "20%",
    borderLeft : "1pt solid #333333",
    fontSize : 8.5,
    paddingVertical : 3,
    paddingHorizontal : 2
  },
  align_center : {
    alignItems : "center"
  },
  justify_center : {
    justifyContent : "center"
  }
});

const DetailsRow = ({title, value}) => (
  <View style={[styles.row, styles.value_row]}>
    <View style={styles.label}>
      <Text>{title}</Text>
    </View>
    <View style={styles.value}>
      <Text>{value}</Text>
    </View>
  </View>
)

const NewLoansTable = ({previous_loans}) => {

  let total_loan_amount = 0;

  return(
    <View>
      <View style={[styles.row, styles.detail_row, { paddingRight : 0, backgroundColor : "#878787", color : "#fff" }]}>
        <View style={[styles.loan_detail_column, {width : "25%", borderLeft : 0}]}>
          <Text> Existing Loan </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "25%"}]}>
          <Text> Type </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "25%"}]}>
          <Text> Amount </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "25%"}]}>
          <Text> Remaining Tenure </Text>
        </View>
      </View>

      {
        previous_loans.map((loan, ind) => {
          total_loan_amount += parseInt(loan.existing_amount || 0)
          return (
            <View style={[styles.row, styles.detail_row, { paddingRight : 0 }]} key={`Loan-${ind}`}>
              <View style={[styles.loan_detail_column, {width : "25%", borderLeft : 0}]}>
                <Text> Loan {ind + 1} </Text>
              </View>
              <View style={[styles.loan_detail_column, {width : "25%"}]}>
                <Text> {loan.type} </Text>
              </View>
              <View style={[styles.loan_detail_column, {width : "25%"}]}>
                <Text> {loan.existing_amount} </Text>
              </View>
              <View style={[styles.loan_detail_column, {width : "25%"}]}>
                <Text> {loan.existing_tenure} </Text>
              </View>
            </View>
          )
        })
      }

      <View style={[styles.row, styles.detail_row, { paddingRight : 0, fontWeight : 700 }]}>
        <View style={[styles.loan_detail_column, {width : "25%", borderLeft : 0}]}>
          <Text> Total </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "25%"}]}>
          
        </View>
        <View style={[styles.loan_detail_column, {width : "25%"}]}>
          <Text> {total_loan_amount} </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "25%"}]}>
          
        </View>
      </View>
    </View>
  )
}

const TopupLoansTable = ({ previous_loans }) => {

  let total_loan_amount = 0;
  let existing_loan_amount = 0;
  let new_loan_amount = 0;

  return(
    <View>
      <View style={[styles.row, styles.detail_row, { paddingRight : 0, backgroundColor : "#878787", color : "#fff" }]}>
        <View style={[styles.loan_detail_column, {width : "11%", borderLeft : 0}]}>
          <Text> Existing Loan </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "5%"}]}>
          <Text> Type </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "12%"}]}>
          <Text> Amount </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "15%"}]}>
          <Text> Remaining Tenure </Text>
        </View>
        <View style={[styles.loan_detail_column, { width : "4%" }]}></View>
        <View style={[styles.loan_detail_column, { borderRight : 0, width : "21%" }]}>
          <Text> Additional Topup Amount </Text>
        </View>
        <View style={[styles.loan_detail_column, { borderRight : 0, width : "20%" }]}>
          <Text> Amount after Top-Up </Text>
        </View>
        <View style={[styles.loan_detail_column, { borderRight : 0, width : "12%" }]}>
          <Text> New Tenure </Text>
        </View>
      </View>

      {
        previous_loans.map((loan, ind) => {
          const current_amount = parseInt(loan.existing_amount || 0);
          const topup_amount = loan.want_topup ? parseInt(loan.topup_amount || 0) : 0;
          const amount_after_topup = current_amount + topup_amount;
         
          new_loan_amount += topup_amount;
          existing_loan_amount += current_amount;
          total_loan_amount += amount_after_topup

          return (
            <View style={[styles.row, styles.detail_row, { paddingRight : 0 }]} key={`Loan-${ind}`}>
              <View style={[styles.loan_detail_column, {width : "11%", borderLeft : 0}]}>
                <Text> Loan {ind + 1} </Text>
              </View>
              <View style={[styles.loan_detail_column, {width : "5%"}]}>
                <Text> {loan.type} </Text>
              </View>
              <View style={[styles.loan_detail_column, {width : "12%"}]}>
                <Text> {loan.existing_amount} </Text>
              </View>
              <View style={[styles.loan_detail_column, {width : "15%"}]}>
                <Text> {loan.existing_tenure} </Text>
              </View>
              
              <View style={[styles.loan_detail_column, styles.row, styles.align_center, styles.justify_center, {width : "4%"}]}>
                {
                  loan.want_topup &&
                  <Image source={Tick} style={{ height : 10, width : 10 }} />
                }
              </View>
              <View style={[styles.loan_detail_column, { width : "21%" }]}>
                <Text> {loan.topup_amount} </Text>
              </View>
              <View style={[styles.loan_detail_column, { width : "20%" }]}>
                <Text> {amount_after_topup} </Text>
              </View>
              <View style={[styles.loan_detail_column, { width : "12%" }]}>
                <Text> {loan.new_tenure} </Text>
              </View>
            </View>
          )
        })
      }

      <View style={[styles.row, styles.detail_row, { paddingRight : 0, fontWeight : 700 }]}>
        <View style={[styles.loan_detail_column, {width : "11%", borderLeft : 0}]}>
          <Text> Total </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "5%"}]}>
          
        </View>
        <View style={[styles.loan_detail_column, {width : "12%"}]}>
          <Text> {existing_loan_amount} </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "15%"}]}>
          
        </View>
        <View style={[styles.loan_detail_column, { width : "4%" }]}></View>
        <View style={[styles.loan_detail_column, { borderRight : 0, width : "21%" }]}>
          <Text> {new_loan_amount} </Text>
        </View>
        <View style={[styles.loan_detail_column, { borderRight : 0, width : "20%" }]}>
          <Text> {total_loan_amount} </Text>
        </View>
        <View style={[styles.loan_detail_column, { borderRight : 0, width : "12%" }]}>
          
        </View>
      </View>
    </View>
  )
}

const RescheduleLoanTable = ({ previous_loans }) => {

  let existing_loan_amount = 0;
  let new_loan_amount = 0;

  return(
    <View>
      <View style={[styles.row, styles.detail_row, { paddingRight : 0, backgroundColor : "#878787", color : "#fff" }]}>
        <View style={[styles.loan_detail_column, {width : "12%", borderLeft : 0}]}>
          <Text> Existing Loan </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "5%"}]}>
          <Text> Type </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "20%"}]}>
          <Text> Amount </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "20%"}]}>
          <Text> Remaining Tenure </Text>
        </View>
        <View style={[styles.loan_detail_column, { width : "4%" }]}></View>
        <View style={[styles.loan_detail_column, { borderRight : 0, width : "21%" }]}>
          <Text> Reschedule Amount </Text>
        </View>
        <View style={[styles.loan_detail_column, { borderRight : 0, width : "18%" }]}>
          <Text> New Tenure </Text>
        </View>
      </View>

      {
        previous_loans.map((loan, ind) => {
          const current_amount = parseInt(loan.existing_amount || 0);
          const topup_amount = loan.want_topup ? parseInt(loan.topup_amount || 0) : 0;
         
          new_loan_amount += topup_amount;
          existing_loan_amount += current_amount;

          return (
            <View style={[styles.row, styles.detail_row, { paddingRight : 0 }]} key={`Loan-${ind}`}>
              <View style={[styles.loan_detail_column, {width : "12%", borderLeft : 0}]}>
                <Text> Loan {ind + 1} </Text>
              </View>
              <View style={[styles.loan_detail_column, {width : "5%"}]}>
                <Text> {loan.type} </Text>
              </View>
              <View style={[styles.loan_detail_column, {width : "20%"}]}>
                <Text> {loan.existing_amount} </Text>
              </View>
              <View style={[styles.loan_detail_column, {width : "20%"}]}>
                <Text> {loan.existing_tenure} </Text>
              </View>
              
              <View style={[styles.loan_detail_column, styles.row, styles.align_center, styles.justify_center, {width : "4%"}]}>
                {
                  loan.want_topup &&
                  <Image source={Tick} style={{ height : 10, width : 10 }} />
                }
              </View>
              <View style={[styles.loan_detail_column, { width : "21%" }]}>
                <Text> {loan.topup_amount} </Text>
              </View>
              <View style={[styles.loan_detail_column, { width : "18%" }]}>
                <Text> {loan.new_tenure} </Text>
              </View>
            </View>
          )
        })
      }

      <View style={[styles.row, styles.detail_row, { paddingRight : 0, fontWeight : 700 }]}>
        <View style={[styles.loan_detail_column, {width : "12%", borderLeft : 0}]}>
          <Text> Total </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "5%"}]}>
          
        </View>
        <View style={[styles.loan_detail_column, {width : "20%"}]}>
          <Text> {existing_loan_amount} </Text>
        </View>
        <View style={[styles.loan_detail_column, {width : "20%"}]}>
          
        </View>
        <View style={[styles.loan_detail_column, { width : "4%" }]}></View>
        <View style={[styles.loan_detail_column, { borderRight : 0, width : "21%" }]}>
          <Text> {new_loan_amount} </Text>
        </View>
        <View style={[styles.loan_detail_column, { borderRight : 0, width : "18%" }]}>
          
        </View>
      </View>
    </View>
  )
}

const UnderwritingSheet = ({ data, underwriting_status}) => {

  const getValue = (field, isDate, dateFormat) => {
    let value = _.get(data, field)
    if(isDate){
      value = moment(value).format(dateFormat)
    }
    return value || "0";
  }

  let existing_loan = false;
  const loan_request_type = getValue("loan_details.loan_request_type");

  if(
    getValue("loan_details.have_existing_loan") === "Yes" ||
    [LOAN_TYPES.TOP_UP, LOAN_TYPES.RESCHEDULE].includes(loan_request_type)
  ){
    existing_loan = true
  }

  return(
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.title}>
            <Text> Underwriting Sheet </Text>
          </View>

          <View style={styles.row}>
            <View style={{width : "45%"}}>

              <DetailsRow title = "Application No" value = {getValue('id')} />
              <DetailsRow title = "Branch Name" value = {getValue('branch_details.bank_branch_name')} />
              <DetailsRow title = "BMI" value = {getValue('bmi')} />
              <DetailsRow title = "Age" value = {getValue('customer_details.age')} />

              
              <View style={{ marginTop : 10 }}>
                <DetailsRow title = "Customer Name" value = {getValue('customer_details.customer_name')} />
                <DetailsRow title = "CIF Number" value = {getValue('customer_details.cif_number')} />
                <DetailsRow title = "Date of Birth" value = {getValue('customer_details.dob', true, "DD-MM-YYYY")} />
                <DetailsRow title = "Gender" value = {getValue('customer_details.gender')} />
                <DetailsRow title = "Height" value = {`${getValue('customer_details.height')} cms`} />
                <DetailsRow title = "Weight" value = {`${getValue('customer_details.weight')} kgs`} />
              </View>

            </View>
            <View style={{width : "55%"}}>

              <DetailsRow title = "Occupation Type" value = {getValue('employment_details.occupation_type')} />
              <DetailsRow title = "Employer Name" value = {getValue('employment_details.employer_name')} />
              <DetailsRow title = "Exact Nature of Duties" value = {getValue('employment_details.exact_nature_of_duties')} />

              <View style={{ marginTop : 20 }}>
                <DetailsRow title = "Loan Type" value = {`${getValue('loan_details.loan_type')}`} />
                <DetailsRow title = "New Loan Amount" value = {`OMR ${getValue('loan_details.new_loan_amount')}`} />
                <DetailsRow title = "Exisitin Loan Amount" value = {`OMR ${getValue('loan_details.existing_loan_amount')}`} />
                <DetailsRow title = "Loan Period" value = {`${getValue('loan_details.loan_tenure')} Months`} />
                <DetailsRow title = "Total loan exposure amount" value = {`OMR ${getValue('loan_details.total_loan_exposure_amount')}`} />
              </View>

            </View>
          </View>

          <View style={styles.status_row}>
            <DetailsRow title = "Preunderwriting status" value = {underwriting_status} />
          </View>

          {
            ([LOAN_TYPES.TOP_UP, LOAN_TYPES.RESCHEDULE].includes(loan_request_type) || existing_loan) &&
            <View style={[styles.row, styles.detail_row, { border : 0 }]}>
              {
                loan_request_type === LOAN_TYPES.NEW &&
                <NewLoansTable previous_loans={data.loan_details.previous_loans} />
              }
              {
                loan_request_type === LOAN_TYPES.TOP_UP &&
                <TopupLoansTable previous_loans={data.loan_details.previous_loans} />
              }
              {
                loan_request_type === LOAN_TYPES.RESCHEDULE &&
                <RescheduleLoanTable previous_loans={data.loan_details.previous_loans} />
              }
            </View>
          }

          {
            data.other_applications.length > 0 &&
            <View style={{ marginTop : 10, paddingHorizontal : 10, borderBottom : "1pt solid #ccc", paddingBottom : 10 }}>
              <Text>
                Other Application
              </Text>

              {
                data.other_applications.map((other_application) => (
                  <View style={[styles.row, styles.align_center, { marginTop : 5, fontSize : 9, paddingLeft : 10 }]}>
                    <View style={{ height : 3, width : 3, borderRadius : "50%", marginRight : 10, backgroundColor : "#000" }}></View>
                    <Text>
                      {other_application.id}
                    </Text>
                  </View>
                ))
              }

            </View>
          }

          <View style={{ marginTop : 10 }}>
            <DetailsRow title = "Civil Id" value = {getValue('customer_details.civil_id')} />
            <DetailsRow title = "Mobile No." value = {getValue('customer_details.contact_number')} />
            <DetailsRow title = "Email" value = {getValue('customer_details.email')} />

            <View style={[styles.row, { marginTop : 10, marginBottom : 6 }]}>
              <View style={styles.half_column}>
                <DetailsRow title = "Submitted By" value = {getValue('agent.name')} />
              </View>
              <View style={styles.half_column}>
                <DetailsRow title = "Submitted At" value = {getValue('submitted_at', true, "DD-MM-YYYY")} />
              </View>
            </View>
          </View>
        </View> 
      </Page>
    </Document>
  )
}

export default UnderwritingSheet;