import { LOAN_TYPES } from "../constants";
import { ReactComponent as Tick } from "../static/icons/tick-icon-black.svg";

const PreviousLoans = ({ data }) => {
  let total_existing_loan_amount = 0;
  let total_loan_amount_with_topup = 0;
  let total_new_loan_amount = 0;
  
  return(
    <div className="pre-underwriting-details" style={{ marginTop : "-1px" }}>
      <table className="w-100 table table-bordered align-middle mb-0">
        <thead>
          <tr>
            <th colSpan={4} className="text-center topup-loan-header">
              Existing Loan Details
            </th>
            {
              data.loan_details.loan_request_type !== LOAN_TYPES.NEW &&
              <th colSpan={4} className="text-center topup-loan-header">
                {data.loan_details.loan_request_type === LOAN_TYPES.RESCHEDULE ? "Rescheduling of Loan" : "Top-Up"}
              </th>
            }
          </tr>
          <tr>
            <th>
              Existing Loan
            </th>
            <th>
              Type
            </th>
            <th>
              Amount
            </th>
            <th>
              Remaining Tenure
            </th>

            {
              data.loan_details.loan_request_type !== LOAN_TYPES.NEW && 
              <>
                <th className="text-center">
                  Selected
                </th>
                <th>
                  {data.loan_details.loan_request_type === LOAN_TYPES.RESCHEDULE ? "Reschedule Amount" : "Additional Top-up Amount"}
                </th>
                {
                  data.loan_details.loan_request_type === LOAN_TYPES.TOP_UP &&
                  <th>
                    Amount after Top-Up
                  </th>
                }
                <th>
                  New Tenure
                </th>
              </>
            }
          </tr>
        </thead>

        <tbody>
          {
            data.loan_details.previous_loans.map((loan, ind) => {
              let currentAmount = parseInt(loan.existing_amount || 0);
              let newLoanAmount = data.loan_details.loan_request_type === LOAN_TYPES.RESCHEDULE ? 0 : currentAmount;

              total_existing_loan_amount += currentAmount;

              if(loan.want_topup){
                let topup_amount = parseInt(loan.topup_amount || 0);
                newLoanAmount += topup_amount;
                total_new_loan_amount += topup_amount;
              }

              total_loan_amount_with_topup += newLoanAmount;

              return(
                <tr key={ind}>
                  <td>
                    <div className="d-flex align-items-center">
                      Loan {ind + 1}
                    </div>
                  </td>
                  <td width={140}>
                    <div className="input-container">
                      {loan.type}
                    </div>
                  </td>
                  <td>
                    <div className="input-container">
                      {loan.existing_amount}
                    </div>
                  </td>
                  <td>
                    <div className="input-container">
                      {loan.existing_tenure}
                    </div>
                  </td>

                  {
                    data.loan_details.loan_request_type !== LOAN_TYPES.NEW && 
                    <>
                      <td>
                        <div className="d-flex justify-content-center align-items-center">
                          {
                            loan.want_topup &&
                            <Tick height={18} />
                          }
                        </div>
                      </td>
                      <td style={{ width : "200px" }}>
                        <div className="input-container">
                          {
                            loan.want_topup ?
                            loan.topup_amount : 
                            <> 0 </>
                          }
                        </div>
                      </td>
                      {
                        data.loan_details.loan_request_type === LOAN_TYPES.TOP_UP &&
                        <td>
                          {newLoanAmount}
                        </td>
                      }
                      <td style={{ width : "200px" }}>
                        <div className="input-container">
                          {
                            loan.want_topup ?
                            loan.new_tenure :
                            loan.existing_tenure
                          }
                        </div>
                      </td>
                    </>
                  }
                </tr>
              )
            })
          }

          <tr>
            <th>
              Total
            </th>
            <th></th>
            <th className="text-center">
              {total_existing_loan_amount}
            </th>
            {
              data.loan_details.loan_request_type !== LOAN_TYPES.NEW &&
              <>
                <th></th>
                <th></th>
                <th className="text-center">
                  {total_new_loan_amount}
                </th>
                {
                  data.loan_details.loan_request_type === LOAN_TYPES.TOP_UP &&
                  <th className="text-center">
                    {total_loan_amount_with_topup}
                  </th>
                }
              </>
            }
            <th></th>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PreviousLoans;